<!--
 * @Author: goodLiang
 * @Date: 2023-02-23 09:22:58
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 10:02:53
 * @FilePath: /vue3-template/src/views/shop/index.vue
-->

<template>
  <div class="app-container">
    <CustomCard>
      <FoldPanel>
        <el-form
          label-position="left"
          label-width="110px"
          v-loading="formLoading"
          ref="searchForm"
          :model="searchForm"
        >
          <el-row :gutter="20">
            <GridItem>
              <el-form-item label="店铺名称" prop="id">
                <el-input
                  v-model.trim="searchForm.shop_name"
                  clearable
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="店铺类型" prop="type">
                <el-select v-model.trim="searchForm.shop_type" clearable>
                  <el-option
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                    v-for="item in shopOptions.shopTypes"
                  ></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
          </el-row>
        </el-form>
        <template #operation>
          <el-button type="primary" @click="handleSearchForm(1)"
            >查询</el-button
          ></template
        >
      </FoldPanel>
      <el-divider></el-divider>
      <div class="i-m-b-20">
        <el-radio-group v-model="apply_state" @change="handleSearchForm(1)">
          <el-radio-button
            v-for="(item, index) in shopOptions.applyTypes"
            :key="index"
            :label="item.id"
            >{{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          background: '#fafafa',
          color: '#444'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        :data="tableData"
      >
        <el-table-column prop="id" label="店铺ID" width="100px">
        </el-table-column>
        <el-table-column prop="shop_name" label="店铺名称"> </el-table-column>
        <el-table-column prop="account" label="申请账号"></el-table-column>
        <el-table-column v-slot="scope" label="入驻类型">
          <div v-if="scope.row.shop_type">
            {{ shopOptions.shopTypes[scope.row.shop_type].name }}
          </div>
        </el-table-column>
        <el-table-column v-slot="scope" label="申请状态">
          {{
            ['审核中', '审核中', '审核成功', '审核失败'][
              scope.row.apply_state - 1
            ]
          }}
        </el-table-column>
        <el-table-column prop="create_time" label="申请时间"> </el-table-column>
        <el-table-column prop="settle_time" label="入住时间"> </el-table-column>
        <el-table-column label="操作" v-slot="scope">
          <div style="display: flex">
            <el-button
              type="primary"
              @click="$router.push(`/shop/detail/${scope.row.id}`)"
              >认证信息</el-button>
            <el-button
              type="primary"
              @click="$router.push(`/shop/property/${scope.row.id}`)"
              >资产账户</el-button>
          </div>
        </el-table-column>
      </el-table>
      <template #bottomOperation>
        <el-pagination
          @size-change="handleSearchForm(1)"
          @current-change="handleSearchForm"
          v-model:current-page="page_index"
          v-model:page-size="page_size"
          layout="prev, pager, next, jumper, sizes"
          :total="page_total"
          class="pagination-box"
        >
        </el-pagination
      ></template>
    </CustomCard>
  </div>
</template>

<script>
import { to } from 'await-to-js'
import shopOptions from '@/const/shop'
import { getShopList } from '@/api/shop'

export default {
  data() {
    return {
      formLoading: false,
      apply_state: '',
      searchForm: {
        shop_name: null,
        shop_type: null
      },
      tableLoading: false,
      tableData: [],
      // 分页配置
      page_index: 1,
      page_size: 10,
      page_total: 10,
      shopOptions
    }
  },
  created() {},
  mounted() {
    this._initData()
  },
  methods: {
    async _initData() {
      const params = {
        page_index: this.page_index,
        page_size: this.page_size,
        ...this.searchForm
      }
      if (this.apply_state) {
        params.apply_states =
          this.apply_state === 1 ? [this.apply_state, 2] : [this.apply_state]
      } else {
        params.apply_states = null
      }
      this.tableLoading = true
      console.log('object :>> ', this)
      const [, res] = await to(getShopList(params))
      this.tableLoading = false
      if (res) {
        this.tableData = res.data
        this.page_total = res.page_count
      }
    },
    async _getShopList() {},
    handleSearchForm(page) {
      this.page_index = page
      this._initData()
    }
  }
}
</script>

<style lang="scss" scoped>
.img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
