/*
 * @Author: goodLiang
 * @Date: 2023-02-24 09:34:05
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-05 19:47:35
 * @FilePath: /vue3-template/src/const/shop.js
 */
export default {
  bankCardType: [
    {
      value: 'DEBIT_CARD',
      label: '对私账户'
    },
    {
      value: 'ENTERPRISE_ACCOUNT',
      label: '对公账户'
    }
  ],
  taxpayerType: [
    {
      value: 1,
      label: '小规模纳税人'
    },
    {
      value: 2,
      label: '一般纳税人'
    }
  ],
  invoiceType: [
    {
      value: 1,
      label: '增值税专票'
    },
    {
      value: 2,
      label: '增值税普票'
    }
  ],
  shopTypes: [
    { id: '', name: '全部' },
    { id: 1, name: '企业' },
    { id: 2, name: '个体工商户' },
    { id: 3, name: '个人' }
  ],
  applyTypes: [
    { id: null, name: '全部' },
    { id: 1, name: '审核中' },
    { id: 3, name: '审核成功' },
    { id: 4, name: '审核失败' }
  ]
}
